import { ConceptValuesForTranslationWeb } from '../../models/concept-values-for-translation-web';
import {
  CompareViewExportFromCollectionCriteria,
  ConceptValuesForTranslation,
  SearchConceptValuesForTranslationsCompareViewRequest
} from '../../../generated/v3';
import {
  ExportCollectionsCompareView,
  SearchCollectionsCompareView
} from '../stores/collections-compare-view/collections-compare-view.action';

export class CollectionsCompareViewMapper {
  static mapToConceptValuesForTranslationWeb(
    concept: ConceptValuesForTranslation
  ): ConceptValuesForTranslationWeb {
    return {
      id: concept.id,
      conceptId: concept.conceptId,
      term: concept.term,
      rawDefinition: concept.rawDefinition,
      jurisdictionsLabel: concept.jurisdictionsLabel,
      translationLabel: concept.translationLabel,
      translationCode: concept.translationCode
    };
  }

  static mapToSearchRequest(
    action: SearchCollectionsCompareView
  ): SearchConceptValuesForTranslationsCompareViewRequest {
    return {
      collectionId: action.payload.collectionId,
      filters: {
        owners: action.payload.searchCriteria?.contentFilters?.['organizations'] || [],
        jurisdictions: action.payload.searchCriteria?.contentFilters?.['jurisdictions'] || [],
        ruleSetFamilyIds: action.payload.searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: action.payload.searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: action.payload.searchCriteria?.contentFilters?.['categories'] || [],
        translations: action.payload.searchCriteria?.contentFilters?.['translations'] || []
      },
      page: action.payload.searchCriteria.page,
      pageSize: action.payload.searchCriteria.pageSize
    };
  }

  static mapToExportRequest(action: ExportCollectionsCompareView): CompareViewExportFromCollectionCriteria {
    return {
      collectionId: action.payload.collectionId,
      excludedConceptIds: action.payload.searchCriteria.excludedIds || [],
      includedConceptIds: action.payload.searchCriteria.selectedIds || [],
      filters: {
        owners: action.payload.searchCriteria?.contentFilters?.['organizations'] || [],
        jurisdictions: action.payload.searchCriteria?.contentFilters?.['jurisdictions'] || [],
        ruleSetFamilyIds: action.payload.searchCriteria?.contentFilters?.['ruleSetFamilies'] || [],
        ruleSetIds: action.payload.searchCriteria?.contentFilters?.['ruleSets'] || [],
        categories: action.payload.searchCriteria?.contentFilters?.['categories'] || [],
        translations: action.payload.searchCriteria?.contentFilters?.['translations'] || []
      }
    };
  }
}
