/**
 * Taxonomy API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AccessibleCategoriesFromCollectionRequest } from '../model/accessibleCategoriesFromCollectionRequest';
// @ts-ignore
import { AccessibleCategoriesFromCollectionResponse } from '../model/accessibleCategoriesFromCollectionResponse';
// @ts-ignore
import { AccessibleJurisdictionsFromCollectionRequest } from '../model/accessibleJurisdictionsFromCollectionRequest';
// @ts-ignore
import { AccessibleJurisdictionsFromCollectionResponse } from '../model/accessibleJurisdictionsFromCollectionResponse';
// @ts-ignore
import { AccessibleOwnersFromCollectionRequest } from '../model/accessibleOwnersFromCollectionRequest';
// @ts-ignore
import { AccessibleOwnersFromCollectionResponse } from '../model/accessibleOwnersFromCollectionResponse';
// @ts-ignore
import { AccessibleRuleSetFamiliesFromCollectionRequest } from '../model/accessibleRuleSetFamiliesFromCollectionRequest';
// @ts-ignore
import { AccessibleRuleSetFamiliesFromCollectionResponse } from '../model/accessibleRuleSetFamiliesFromCollectionResponse';
// @ts-ignore
import { AccessibleTranslationsFromCollectionRequest } from '../model/accessibleTranslationsFromCollectionRequest';
// @ts-ignore
import { AccessibleTranslationsFromCollectionResponse } from '../model/accessibleTranslationsFromCollectionResponse';
// @ts-ignore
import { CompareViewExportFromCollectionCriteria } from '../model/compareViewExportFromCollectionCriteria';
// @ts-ignore
import { SearchConceptValuesForTranslationsCompareViewRequest } from '../model/searchConceptValuesForTranslationsCompareViewRequest';
// @ts-ignore
import { SearchConceptValuesForTranslationsCompareViewResponse } from '../model/searchConceptValuesForTranslationsCompareViewResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class CompareViewService {

    protected basePath = 'http://localhost:9091';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Retrieve a list of categories the user has access to based on the collections concepts the user has access to
     * @param accessibleCategoriesFromCollectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessibleCategoriesFromCollection(accessibleCategoriesFromCollectionRequest: AccessibleCategoriesFromCollectionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AccessibleCategoriesFromCollectionResponse>;
    public accessibleCategoriesFromCollection(accessibleCategoriesFromCollectionRequest: AccessibleCategoriesFromCollectionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AccessibleCategoriesFromCollectionResponse>>;
    public accessibleCategoriesFromCollection(accessibleCategoriesFromCollectionRequest: AccessibleCategoriesFromCollectionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AccessibleCategoriesFromCollectionResponse>>;
    public accessibleCategoriesFromCollection(accessibleCategoriesFromCollectionRequest: AccessibleCategoriesFromCollectionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (accessibleCategoriesFromCollectionRequest === null || accessibleCategoriesFromCollectionRequest === undefined) {
            throw new Error('Required parameter accessibleCategoriesFromCollectionRequest was null or undefined when calling accessibleCategoriesFromCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<AccessibleCategoriesFromCollectionResponse>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/accessible-categories-from-collection`,
            accessibleCategoriesFromCollectionRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a list of jurisdictions the user has access to based on the collections concepts the user has access to
     * @param accessibleJurisdictionsFromCollectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessibleJurisdictionsFromCollection(accessibleJurisdictionsFromCollectionRequest: AccessibleJurisdictionsFromCollectionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AccessibleJurisdictionsFromCollectionResponse>;
    public accessibleJurisdictionsFromCollection(accessibleJurisdictionsFromCollectionRequest: AccessibleJurisdictionsFromCollectionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AccessibleJurisdictionsFromCollectionResponse>>;
    public accessibleJurisdictionsFromCollection(accessibleJurisdictionsFromCollectionRequest: AccessibleJurisdictionsFromCollectionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AccessibleJurisdictionsFromCollectionResponse>>;
    public accessibleJurisdictionsFromCollection(accessibleJurisdictionsFromCollectionRequest: AccessibleJurisdictionsFromCollectionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (accessibleJurisdictionsFromCollectionRequest === null || accessibleJurisdictionsFromCollectionRequest === undefined) {
            throw new Error('Required parameter accessibleJurisdictionsFromCollectionRequest was null or undefined when calling accessibleJurisdictionsFromCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<AccessibleJurisdictionsFromCollectionResponse>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/accessible-jurisdictions-from-collection`,
            accessibleJurisdictionsFromCollectionRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a list of owners the user has access to based on the collections concepts the user has access to
     * @param accessibleOwnersFromCollectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessibleOwnersFromCollection(accessibleOwnersFromCollectionRequest: AccessibleOwnersFromCollectionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AccessibleOwnersFromCollectionResponse>;
    public accessibleOwnersFromCollection(accessibleOwnersFromCollectionRequest: AccessibleOwnersFromCollectionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AccessibleOwnersFromCollectionResponse>>;
    public accessibleOwnersFromCollection(accessibleOwnersFromCollectionRequest: AccessibleOwnersFromCollectionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AccessibleOwnersFromCollectionResponse>>;
    public accessibleOwnersFromCollection(accessibleOwnersFromCollectionRequest: AccessibleOwnersFromCollectionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (accessibleOwnersFromCollectionRequest === null || accessibleOwnersFromCollectionRequest === undefined) {
            throw new Error('Required parameter accessibleOwnersFromCollectionRequest was null or undefined when calling accessibleOwnersFromCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<AccessibleOwnersFromCollectionResponse>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/accessible-owners-from-collection`,
            accessibleOwnersFromCollectionRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a list of rule set families the user has access to based on the collections concepts the user has access to
     * @param accessibleRuleSetFamiliesFromCollectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessibleRuleSetFamiliesFromCollection(accessibleRuleSetFamiliesFromCollectionRequest: AccessibleRuleSetFamiliesFromCollectionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AccessibleRuleSetFamiliesFromCollectionResponse>;
    public accessibleRuleSetFamiliesFromCollection(accessibleRuleSetFamiliesFromCollectionRequest: AccessibleRuleSetFamiliesFromCollectionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AccessibleRuleSetFamiliesFromCollectionResponse>>;
    public accessibleRuleSetFamiliesFromCollection(accessibleRuleSetFamiliesFromCollectionRequest: AccessibleRuleSetFamiliesFromCollectionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AccessibleRuleSetFamiliesFromCollectionResponse>>;
    public accessibleRuleSetFamiliesFromCollection(accessibleRuleSetFamiliesFromCollectionRequest: AccessibleRuleSetFamiliesFromCollectionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (accessibleRuleSetFamiliesFromCollectionRequest === null || accessibleRuleSetFamiliesFromCollectionRequest === undefined) {
            throw new Error('Required parameter accessibleRuleSetFamiliesFromCollectionRequest was null or undefined when calling accessibleRuleSetFamiliesFromCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<AccessibleRuleSetFamiliesFromCollectionResponse>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/accessible-rule-set-families-from-collection`,
            accessibleRuleSetFamiliesFromCollectionRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a list of translations the user has access to based on the collections concepts the user has access to
     * @param accessibleTranslationsFromCollectionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessibleTranslationsFromCollection(accessibleTranslationsFromCollectionRequest: AccessibleTranslationsFromCollectionRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<AccessibleTranslationsFromCollectionResponse>;
    public accessibleTranslationsFromCollection(accessibleTranslationsFromCollectionRequest: AccessibleTranslationsFromCollectionRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<AccessibleTranslationsFromCollectionResponse>>;
    public accessibleTranslationsFromCollection(accessibleTranslationsFromCollectionRequest: AccessibleTranslationsFromCollectionRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<AccessibleTranslationsFromCollectionResponse>>;
    public accessibleTranslationsFromCollection(accessibleTranslationsFromCollectionRequest: AccessibleTranslationsFromCollectionRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (accessibleTranslationsFromCollectionRequest === null || accessibleTranslationsFromCollectionRequest === undefined) {
            throw new Error('Required parameter accessibleTranslationsFromCollectionRequest was null or undefined when calling accessibleTranslationsFromCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<AccessibleTranslationsFromCollectionResponse>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/accessible-translations-from-collection`,
            accessibleTranslationsFromCollectionRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Endpoint to perform compare view collection export
     * This will return the csv file containing all the concepts matching the criteria from the request and that the user has access to.
     * @param compareViewExportFromCollectionCriteria 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportFromCollection(compareViewExportFromCollectionCriteria: CompareViewExportFromCollectionCriteria, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<any>;
    public exportFromCollection(compareViewExportFromCollectionCriteria: CompareViewExportFromCollectionCriteria, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<HttpResponse<any>>;
    public exportFromCollection(compareViewExportFromCollectionCriteria: CompareViewExportFromCollectionCriteria, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv',}): Observable<HttpEvent<any>>;
    public exportFromCollection(compareViewExportFromCollectionCriteria: CompareViewExportFromCollectionCriteria, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv',}): Observable<any> {
        if (compareViewExportFromCollectionCriteria === null || compareViewExportFromCollectionCriteria === undefined) {
            throw new Error('Required parameter compareViewExportFromCollectionCriteria was null or undefined when calling exportFromCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/export-from-collection`,
            compareViewExportFromCollectionCriteria,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a page of concept values for translations associated with a collection
     * @param searchConceptValuesForTranslationsCompareViewRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchConceptValuesTranslationsCompareView(searchConceptValuesForTranslationsCompareViewRequest: SearchConceptValuesForTranslationsCompareViewRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SearchConceptValuesForTranslationsCompareViewResponse>;
    public searchConceptValuesTranslationsCompareView(searchConceptValuesForTranslationsCompareViewRequest: SearchConceptValuesForTranslationsCompareViewRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SearchConceptValuesForTranslationsCompareViewResponse>>;
    public searchConceptValuesTranslationsCompareView(searchConceptValuesForTranslationsCompareViewRequest: SearchConceptValuesForTranslationsCompareViewRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SearchConceptValuesForTranslationsCompareViewResponse>>;
    public searchConceptValuesTranslationsCompareView(searchConceptValuesForTranslationsCompareViewRequest: SearchConceptValuesForTranslationsCompareViewRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        if (searchConceptValuesForTranslationsCompareViewRequest === null || searchConceptValuesForTranslationsCompareViewRequest === undefined) {
            throw new Error('Required parameter searchConceptValuesForTranslationsCompareViewRequest was null or undefined when calling searchConceptValuesTranslationsCompareView.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Authorization) required
        localVarCredential = this.configuration.lookupCredential('Authorization');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<SearchConceptValuesForTranslationsCompareViewResponse>(`${this.configuration.basePath}/api/taxonomy/collections/compare-view/search-concept-values-for-translations-compare-view`,
            searchConceptValuesForTranslationsCompareViewRequest,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
