import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';

export enum TaxonomyLatestChangesAction {
  SearchLatestChanges = '[Search Latest Changes] Performs a search of the latest changes',
  ExportLatestChanges = '[Export Latest Changes] Performs a export of the latest changes',
  SetTaxonomyLatestChangesStorageData = '[Set Latest Changes Storage Data] save the storage data',
  SetTaxonomyLatestChangesStorageFilterData = '[Set Latest Changes Filter Storage Data] save the filter storage data'
}

export class SearchLatestChanges {
  public static readonly type = TaxonomyLatestChangesAction.SearchLatestChanges;

  constructor(public searchCriteria: SearchCriteria) {
  }
}

export class ExportLatestChanges {
  public static readonly type = TaxonomyLatestChangesAction.ExportLatestChanges;

  constructor(public searchCriteria: SearchCriteria) {
  }
}

export class SetTaxonomyLatestChangesStorageData {
  public static readonly type = TaxonomyLatestChangesAction.SetTaxonomyLatestChangesStorageData;

  constructor(public storageData: StorageData) {
  }
}

export class SetTaxonomyLatestChangesStorageFilterData {
  public static readonly type = TaxonomyLatestChangesAction.SetTaxonomyLatestChangesStorageFilterData;

  constructor(public storageData: Map<string, FilterData>) {
  }
}
