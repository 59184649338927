import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Application, NavigationBarConfig, NavigationBarService } from '@apiax/web-commons';
import { User as NavigationBarUser } from '@apiax/web-commons/lib/navigation-bar/navigation-bar.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { getConfig } from 'shared/app/config';
import { AuthorizationService } from 'shared/core';

@UntilDestroy()
@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public currentUser: NavigationBarUser;
  public userPhotoUrl$ = new BehaviorSubject<any>(null);
  public navigationBarConfig: NavigationBarConfig;
  public collapsed: boolean;

  constructor(
    public authService: AuthorizationService,
    private navBarService: NavigationBarService,
    private router: Router
  ) {
    this.getUser();

    this.navigationBarConfig = {
      appsUrl: getConfig('appsWebUrl'),
      rulesUrl: getConfig('rulesWebUrl'),
      taxonomyUrl: getConfig('taxonomyWebUrl'),
      reportingUrl: getConfig('reportingWebUrl'),
      companyManagerUrl: getConfig('companyManagerWebUrl'),
      monitoringUrl: getConfig('monitoringWebUrl'),
      clientProfileManagerUrl: getConfig('clientProfileManagerWebUrl'),
      instrumentManagerUrl: getConfig('instrumentManagerWebUrl'),
      instrumentsCheckUrl: getConfig('instrumentsCheckWebUrl'),
      documentsUrl: getConfig('documentsWebUrl'),
      chatbotUrl: getConfig('chatbotWebUrl'),
      requestsUrl: getConfig('requestsWebUrl'),
      knowledgeBaseUrl: getConfig('knowledgebaseWebUrl'),
      contentCheckUrl: getConfig('contentCheckWebUrl'),
      userPhotoUrl$: this.userPhotoUrl$,
      currentApplication: Application.Taxonomy,
      homeUrl: '',
      currenApplicationForceReload: true
    };

    this.router.events
      .pipe(
        filter(val => val instanceof ActivationEnd),
        untilDestroyed(this)
      )
      .subscribe((val: any) => {
        const urlParts = (val.snapshot as ActivatedRouteSnapshot).url;
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((event: NavigationEnd) => {
        this.navBarService.updateSelectedMenuItem(event.urlAfterRedirects, Application.Taxonomy);
      });
  }

  private getUser() {
    this.authService.currentUser.pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        this.currentUser = {
          organizationName: user.organization?.name || '',
          firstName: user.firstName,
          lastName: user.lastName,
          organizationId: user.organizationId,
          photoId: user.photoId,
          permissions: user.activities
        };

        this.getUserPhotoUrl();
      }
    });
  }

  private getUserPhotoUrl() {
    if (this.currentUser.photoId) {
      this.userPhotoUrl$.next(getConfig('userPhotoBaseUrl') + this.currentUser.photoId);
    } else {
      this.userPhotoUrl$.next(null);
    }
  }

  private listenCollapseNavBar() {
    this.navBarService
      .getCollapsible()
      .pipe(untilDestroyed(this))
      .subscribe(collapsed => {
        this.collapsed = collapsed;
      });
  }

  ngOnInit() {
    this.listenCollapseNavBar();
  }

  public logout() {
    this.authService.logout();
  }
}
