import { StateContext } from '@ngxs/store';
import { CompareViewService } from '../../../../../generated/v3';
import { CollectionsCompareViewStateModel, DEFAULT_STATE } from '../collections-compare-view.state';
import {
  ExportCollectionsCompareView,
  ResetCollectionsCompareViewData,
  SearchCollectionsCompareView,
  SetCollectionsCompareViewCollection,
  SetCollectionsCompareViewFilterStorageData,
  SetCollectionsCompareViewStorageData
} from '../collections-compare-view.action';
import { CollectionsCompareViewMapper } from '../../../mappers/collections-compare-view-mapper';
import { catchError, tap } from 'rxjs/operators';
import { FileUtils } from '../../../utils/file.utils';
import { throwError } from 'rxjs';

export class CollectionsCompareViewStateUseCases {
  constructor(private compareViewApiService: CompareViewService) {
  }

  public setCollectionId(
    context: StateContext<CollectionsCompareViewStateModel>,
    action: SetCollectionsCompareViewCollection
  ) {
    context.patchState({
      collectionId: action.collectionId
    });
  }

  public resetData(context: StateContext<CollectionsCompareViewStateModel>, _action: ResetCollectionsCompareViewData) {
    context.setState(DEFAULT_STATE);
  }

  public search(context: StateContext<CollectionsCompareViewStateModel>, action: SearchCollectionsCompareView) {
    context.patchState({
      isLoadingSearch: true
    });
    return this.compareViewApiService
      .searchConceptValuesTranslationsCompareView(CollectionsCompareViewMapper.mapToSearchRequest(action))
      .pipe(
        tap(response => {
          context.patchState({
            searchResults: response.results.map(result =>
              CollectionsCompareViewMapper.mapToConceptValuesForTranslationWeb(result)
            ),
            isLoadingSearch: false,
            totalResults: response.total,
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public export(context: StateContext<CollectionsCompareViewStateModel>, action: ExportCollectionsCompareView) {
    return this.compareViewApiService
      .exportFromCollection(CollectionsCompareViewMapper.mapToExportRequest(action))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'collectionList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public setStorageData(
    context: StateContext<CollectionsCompareViewStateModel>,
    action: SetCollectionsCompareViewStorageData
  ) {
    context.patchState({
      storageData: action.storageData
    });
  }

  public setFilterStorageData(
    context: StateContext<CollectionsCompareViewStateModel>,
    action: SetCollectionsCompareViewFilterStorageData
  ) {
    // web-commons is returning a Map<string, FilterData> however NGXS does not support MAP. So we need to convert it to object
    context.patchState({
      filterStorageData: Object.fromEntries(action.storageData)
    });
  }
}
