import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TaxonomyLatestChangesStateUseCases } from './usecases/taxonomy-latest-changes-usecases.state';
import { FilterData, StorageData } from '@apiax/web-commons';
import { LatestChange } from '../../../models/latest-change';
import { LatestChangeApiService } from '../../../../generated/v3';
import {
  ExportLatestChanges,
  SearchLatestChanges,
  SetTaxonomyLatestChangesStorageData,
  SetTaxonomyLatestChangesStorageFilterData
} from './taxonomy-latest-changes.action';

export interface TaxonomyLatestChangesStateModel {
  latestChangesSearchResults: LatestChange[];
  latestChangesTotalResults: number;
  isLoadingLatestChangesSearch: boolean;
  storageData: StorageData;
  filterStorageData: { [key: string]: FilterData };
}

export const DEFAULT_STATE: TaxonomyLatestChangesStateModel = {
  latestChangesSearchResults: [],
  latestChangesTotalResults: undefined,
  isLoadingLatestChangesSearch: false,
  storageData: {
    filtersSelectionData: undefined,
    sortBy: undefined,
    selected: [],
    sortOrder: 'ASC',
    isSeeingSelection: false,
    searchValue: undefined,
    page: 0
  },
  filterStorageData: {}
};

@State<TaxonomyLatestChangesStateModel>({
  name: 'taxonomyLatestChanges',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomyLatestChangesState {
  private taxonomyLatestChangesStateUseCases: TaxonomyLatestChangesStateUseCases;

  @Selector()
  public static latestChangesSearchResults(state: TaxonomyLatestChangesStateModel): LatestChange[] {
    return state.latestChangesSearchResults;
  }

  @Selector()
  public static isLoadingLatestChangesSearch(state: TaxonomyLatestChangesStateModel): boolean {
    return state.isLoadingLatestChangesSearch;
  }

  @Selector()
  public static latestChangesTotalResults(state: TaxonomyLatestChangesStateModel): number {
    return state.latestChangesTotalResults;
  }

  @Selector()
  public static storageData(state: TaxonomyLatestChangesStateModel): StorageData {
    return state.storageData;
  }

  @Selector()
  public static filterStorageData(state: TaxonomyLatestChangesStateModel): { [key: string]: FilterData } {
    return state.filterStorageData;
  }

  constructor(latestChangeApiService: LatestChangeApiService) {
    this.taxonomyLatestChangesStateUseCases = new TaxonomyLatestChangesStateUseCases(latestChangeApiService);
  }

  @Action(SearchLatestChanges, { cancelUncompleted: true })
  public searchLatestChanges(context: StateContext<TaxonomyLatestChangesStateModel>, action: SearchLatestChanges) {
    return this.taxonomyLatestChangesStateUseCases.search(context, action);
  }

  @Action(ExportLatestChanges)
  public export(context: StateContext<TaxonomyLatestChangesStateModel>, action: ExportLatestChanges) {
    return this.taxonomyLatestChangesStateUseCases.export(action, context);
  }

  @Action(SetTaxonomyLatestChangesStorageData)
  public setStorageData(
    context: StateContext<TaxonomyLatestChangesStateModel>,
    action: SetTaxonomyLatestChangesStorageData
  ) {
    return this.taxonomyLatestChangesStateUseCases.setStorageData(context, action);
  }

  @Action(SetTaxonomyLatestChangesStorageFilterData)
  public setFilterStorageData(
    context: StateContext<TaxonomyLatestChangesStateModel>,
    action: SetTaxonomyLatestChangesStorageFilterData
  ) {
    return this.taxonomyLatestChangesStateUseCases.setFilterStorageData(context, action);
  }
}
