import { FilterData, SearchCriteria, StorageData } from '@apiax/web-commons';

export enum TaxonomySearchOnRulesAction {
  SearchOnRules = '[Search on rules] perform a search for concepts based on rule criteria',
  SearchOnRulesExport = '[Search on rules Export] perform a export for concepts based on rule criteria',
  SetSearchOnRulesStorageData = '[Set Latest Changes Storage Data] save the storage data',
  SetSearchOnRulesStorageFilterData = '[Set Latest Changes Filter Storage Data] save the filter storage data'
}

export class SearchOnRules {
  public static readonly type = TaxonomySearchOnRulesAction.SearchOnRules;

  constructor(public searchCriteria: SearchCriteria) {
  }
}

export class SearchOnRulesExport {
  public static readonly type = TaxonomySearchOnRulesAction.SearchOnRulesExport;

  constructor(public searchCriteria: SearchCriteria) {
  }
}

export class SetSearchOnRulesStorageData {
  public static readonly type = TaxonomySearchOnRulesAction.SetSearchOnRulesStorageData;

  constructor(public storageData: StorageData) {
  }
}

export class SetSearchOnRulesStorageFilterData {
  public static readonly type = TaxonomySearchOnRulesAction.SetSearchOnRulesStorageFilterData;

  constructor(public storageData: Map<string, FilterData>) {
  }
}
