import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  Column,
  DataTableComponent,
  Filter,
  FilterData,
  NotificationService,
  SearchCriteria,
  StorageData
} from '@apiax/web-commons';
import { DataTableSearchMetadata } from '@apiax/web-commons/lib/data-table/data-table.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Activity } from 'shared/core';
import { TaxonomyUtilsService } from '../../../../domain/services/taxonomy-utils.service';
import { ResetConcept } from '../../../../domain/stores/taxonomy-concept';
import { LoadSearchOnRulesFilterOptions } from '../../../../domain/stores/taxonomy-filters/taxonomy-filters.action';
import { TaxonomyFiltersState } from '../../../../domain/stores/taxonomy-filters/taxonomy-filters.state';
import {
  TaxonomySearchOnRulesState
} from '../../../../domain/stores/taxonomy-search-on-rules/taxonomy-search-on-rules-state.service';
import {
  SearchOnRules,
  SearchOnRulesExport,
  SetSearchOnRulesStorageData,
  SetSearchOnRulesStorageFilterData
} from '../../../../domain/stores/taxonomy-search-on-rules/taxonomy-search-on-rules.action';
import { FilterUtils } from '../../../../domain/utils/filter.utils';
import { ConceptSearchResult, FilterOption } from '../../../../models';
import { RuleSetFilterOption } from '../../../../models/rule-set-filter-option';
import { ConceptTableUtils } from '../../../shared-components/utils/concept-table-utils';

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-search-on-rules',
  templateUrl: './taxonomy-search-on-rules.component.html',
  styleUrls: ['./taxonomy-search-on-rules.component.scss']
})
export class TaxonomySearchOnRulesComponent implements OnInit {
  public readonly PAGE_SIZE = 10;

  @ViewChild('table')
  table: DataTableComponent<any>;
  public isDownloading$ = new BehaviorSubject(false);
  public downloadPermission = Activity.ViewTerms;

  public filters: Filter[];
  public columns: Column[];
  public search: DataTableSearchMetadata = {
    width: '568px',
    defaultText: '',
    placeholder: 'Search taxonomy terms on rules'
  };

  public searchResults: ConceptSearchResult[];
  public totalResults: number;
  public page: number;

  isLoading$: Observable<boolean>;
  idFetcher = obj => obj.id;

  constructor(
    private router: Router,
    private store: Store,
    private notificationService: NotificationService,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.taxonomyUtilsService.searchOnRulesSelectedTabIndex = 0;
    this.isLoading$ = this.store.select(TaxonomyFiltersState.isSearchOnRulesFiltersLoaded).pipe(
      untilDestroyed(this),
      map(value => !value)
    );
    this.buildColumns();
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'term',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 5
      },
      {
        prop: 'termId',
        label: 'Term ID',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 5
      },
      {
        prop: 'rawDefinition',
        label: 'Definition',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 5
      },
      {
        prop: 'ruleSetsLabel',
        label: 'Rule set',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'jurisdictionsLabel',
        label: 'Jurisdiction',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'ownerLabel',
        label: 'Owner',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'categoriesLabel',
        label: 'Category',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'isPrivate',
        label: 'Privacy',
        transformValue: value => ConceptTableUtils.getPrivacyIcon(value),
        tooltip: value => ConceptTableUtils.getPrivacyToolTip(value),
        sortable: false,
        extraClasses: 'center-aligned',
        size: 1.5
      },
      {
        prop: 'state',
        label: 'Status',
        transformValue: value => ConceptTableUtils.getStatusLabel(value),
        tooltip: value => value,
        sortable: false,
        extraClasses: 'center-aligned',
        size: 2
      }
    ];
  }

  private loadFilterOptions() {
    if (!this.store.selectSnapshot(TaxonomyFiltersState.isSearchOnRulesFiltersLoaded)) {
      this.store
        .dispatch(new LoadSearchOnRulesFilterOptions())
        .pipe(first())
        .subscribe(() => this.buildFilters());
    } else {
      this.buildFilters();
    }
  }

  private initSubscriptions() {
    this.store
      .select(TaxonomySearchOnRulesState.searchResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.searchResults = results));

    this.store
      .select(TaxonomySearchOnRulesState.totalResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalResults = results));
  }

  private buildFilters() {
    combineLatest([
      this.store.select(TaxonomyFiltersState.jurisdictionOptions),
      this.store.select(TaxonomyFiltersState.organizationOptions),
      this.store.select(TaxonomyFiltersState.usageRuleSetFamilyOptions),
      this.store.select(TaxonomyFiltersState.usageRuleSetOptions),
      this.store.select(TaxonomyFiltersState.documentTypeOptions),
      this.store.select(TaxonomyFiltersState.documentActivationStatusOptions),
      this.store.select(TaxonomyFiltersState.documentVersionOptions)
    ])
      .pipe(first())
      .subscribe(
        ([
           jurisdictions,
           organizations,
           ruleSetFamilies,
           ruleSets,
           documentTypes,
           documentActivationStatus,
           documentVersions
         ]) => {
          ruleSetFamilies = ruleSetFamilies as FilterOption[];
          const ruleSetFamiliesFilter = FilterUtils.buildRuleSetFamiliesFilter(ruleSetFamilies);
          const ruleSetsFilter = FilterUtils.buildRuleSetsFilter(ruleSets as RuleSetFilterOption[]);
          this.initReactRuleSetToRuleSetFamiliesChanges(
            ruleSetFamiliesFilter,
            ruleSets as RuleSetFilterOption[],
            ruleSetsFilter
          );
          this.filters = [
            ruleSetFamiliesFilter,
            ruleSetsFilter,
            FilterUtils.buildOrganizationFilter(organizations as FilterOption[], 'Content by'),
            FilterUtils.buildJurisdictionsFilter(jurisdictions as FilterOption[]),
            FilterUtils.buildDocumentTypesFilter(documentTypes as FilterOption[]),
            FilterUtils.buildDocumentActivationStatusFilter(documentActivationStatus as FilterOption[]),
            FilterUtils.buildDocumentVersionsFilter(documentVersions as FilterOption[])
          ];
        }
      );
  }

  private initReactRuleSetToRuleSetFamiliesChanges(
    ruleSetFamiliesFilter: Filter,
    ruleSets: RuleSetFilterOption[],
    ruleSetsFilter: Filter
  ) {
    ruleSetFamiliesFilter.selectionChange$.pipe(untilDestroyed(this)).subscribe(selectedRuleSetFamilyFilterOptions => {
      FilterUtils.rebuildRuleSetFilter(selectedRuleSetFamilyFilterOptions, ruleSets, ruleSetsFilter);
    });
  }

  ngOnInit() {
    this.store.dispatch(new ResetConcept());
    this.loadFilterOptions();
    this.initSubscriptions();
  }

  public fetchData(searchCriteria: SearchCriteria) {
    this.store.dispatch(new SearchOnRules(searchCriteria));
  }

  public onSelect(row) {
    this.router.navigate(['concept', 'term-editor'], {
      queryParams: { conceptId: row.id }
    });
  }

  public downloadData(searchCriteria: SearchCriteria) {
    this.store
      .dispatch(new SearchOnRulesExport(searchCriteria))
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert('Search on rules list downloaded successfully', 'success');
      });
  }

  saveToStorageStore = (data: StorageData) => {
    this.store.dispatch(new SetSearchOnRulesStorageData(data));
  };

  loadFromStorageStore = (): StorageData => {
    return this.store.selectSnapshot(TaxonomySearchOnRulesState.storageData);
  };

  filterSaveToStore = (data: Map<string, FilterData>) => {
    this.store.dispatch(new SetSearchOnRulesStorageFilterData(data));
  };

  filterLoadFromStore: () => Map<string, FilterData> = (): Map<string, FilterData> => {
    const filterStorageData = this.store.selectSnapshot(TaxonomySearchOnRulesState.filterStorageData);
    return new Map(Object.entries(filterStorageData));
  };
}
