import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConceptApiService } from '../../../../generated/v3';
import { ConceptSearchResult } from '../../../models';
import { TaxonomySearchOnRulesStateUseCases } from './usecases/taxonomy-search-on-rules-usecases.state';
import { FilterData, StorageData } from '@apiax/web-commons';
import {
  SearchOnRules,
  SearchOnRulesExport,
  SetSearchOnRulesStorageData,
  SetSearchOnRulesStorageFilterData
} from './taxonomy-search-on-rules.action';
import { SetTaxonomyLatestChangesStorageFilterData } from '../taxonomy-latest-changes/taxonomy-latest-changes.action';

export interface TaxonomySearchOnRulesStateModel {
  searchResults: ConceptSearchResult[];
  totalResults: number;
  isLoadingSearch: boolean;
  storageData: StorageData;
  filterStorageData: { [key: string]: FilterData };
}

export const DEFAULT_STATE: TaxonomySearchOnRulesStateModel = {
  searchResults: [],
  totalResults: undefined,
  isLoadingSearch: false,
  storageData: {
    filtersSelectionData: undefined,
    sortBy: undefined,
    selected: [],
    sortOrder: 'ASC',
    isSeeingSelection: false,
    searchValue: undefined,
    page: undefined
  },
  filterStorageData: {}
};

@State<TaxonomySearchOnRulesStateModel>({
  name: 'taxonomySearchOnRules',
  defaults: DEFAULT_STATE
})
@Injectable()
export class TaxonomySearchOnRulesState {
  private taxonomyConceptRuleSearchStateUseCases: TaxonomySearchOnRulesStateUseCases;

  @Selector()
  public static searchResults(state: TaxonomySearchOnRulesStateModel): ConceptSearchResult[] {
    return state.searchResults;
  }

  @Selector()
  public static isLoadingSearch(state: TaxonomySearchOnRulesStateModel): boolean {
    return state.isLoadingSearch;
  }

  @Selector()
  public static totalResults(state: TaxonomySearchOnRulesStateModel): number {
    return state.totalResults;
  }

  @Selector()
  public static storageData(state: TaxonomySearchOnRulesStateModel): StorageData {
    return state.storageData;
  }

  @Selector()
  public static filterStorageData(state: TaxonomySearchOnRulesStateModel): { [key: string]: FilterData } {
    return state.filterStorageData;
  }

  constructor(taxonomyV3ApiService: ConceptApiService) {
    this.taxonomyConceptRuleSearchStateUseCases = new TaxonomySearchOnRulesStateUseCases(taxonomyV3ApiService);
  }

  @Action(SearchOnRules, { cancelUncompleted: true })
  public searchOnRules(context: StateContext<TaxonomySearchOnRulesStateModel>, { searchCriteria }: SearchOnRules) {
    return this.taxonomyConceptRuleSearchStateUseCases.searchOnRules(context, searchCriteria);
  }

  @Action(SearchOnRulesExport)
  public searchOnRulesExport(
    context: StateContext<TaxonomySearchOnRulesStateModel>,
    { searchCriteria }: SearchOnRulesExport
  ) {
    return this.taxonomyConceptRuleSearchStateUseCases.searchOnRulesExport(context, searchCriteria);
  }

  @Action(SetSearchOnRulesStorageData)
  public setStorageData(
    context: StateContext<TaxonomySearchOnRulesStateModel>,
    action: SetSearchOnRulesStorageData
  ) {
    return this.taxonomyConceptRuleSearchStateUseCases.setStorageData(context, action);
  }

  @Action(SetTaxonomyLatestChangesStorageFilterData)
  public setFilterStorageData(
    context: StateContext<TaxonomySearchOnRulesStateModel>,
    action: SetSearchOnRulesStorageFilterData
  ) {
    return this.taxonomyConceptRuleSearchStateUseCases.setFilterStorageData(context, action);
  }
}
