import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Column,
  DataTableComponent,
  MenuAction,
  MenuActionInput,
  NotificationService,
  RowIcon,
  SearchCriteria,
  SortOrder,
  StorageData
} from '@apiax/web-commons';
import { Activity, AuthorizationService } from 'shared/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  RemoveFromMyCollectionModalComponent
} from '../../../../shared-components/modals/remove-from-my-collection-modal/remove-from-my-collection-modal.component';
import { SearchConceptsByMyCollectionRequest } from '../../../../../../generated/v3';
import { isEmpty } from 'lodash-es';
import { ConceptTableUtils } from '../../../../shared-components/utils/concept-table-utils';
import { CollectionsMapper } from '../../../../../domain/mappers/collections-mapper';
import { Store } from '@ngxs/store';
import {
  ExportTerms,
  SetCollectionsStorageData,
  SetMyCollectionsData
} from '../../../../../domain/stores/collections/collections.action';
import { Router } from '@angular/router';
import { CollectionsState } from '../../../../../domain/stores/collections/collections.state';
import { ConceptCollection } from '../../../../../models/concept-collection';

@UntilDestroy()
@Component({
  selector: 'app-my-collection-content',
  templateUrl: './my-collection-content.component.html',
  styleUrls: ['./my-collection-content.component.scss']
})
export class MyCollectionContentComponent implements OnInit, AfterViewInit {
  public data: ConceptCollection[] = [];
  public totalData = 0;
  public readonly pageSize = 10;

  @ViewChild(DataTableComponent) table: DataTableComponent<never> | undefined;

  defaultSortBy = 'termId';
  defaultSortOrder = SortOrder.ASC;
  currentUserPermissions = new Map<Activity, boolean>();
  rowIcon: RowIcon;

  public columns: Column[];

  public menuActions: MenuAction[] = [
    {
      permissions: [],
      icon: 'delete',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action critical-plain',
      title: 'Remove from <i>My collections</i>',
      tooltip: 'Remove from My collections',
      actionFunction: (input: MenuActionInput) => {
        return this.remove(input);
      }
    }
  ];

  private searchCriteria: SearchCriteria = {
    page: 0,
    pageSize: this.pageSize
  };

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public downloadPermission = Activity.ViewTerms;
  public isDownloading$ = new BehaviorSubject(false);

  constructor(
    private authorizationService: AuthorizationService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private store: Store,
    private router: Router
  ) {
    this.getCurrentUserPermissions();
    this.buildColumns();
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'term',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2.5
      },
      {
        prop: 'termId',
        label: 'Term ID',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2.5
      },
      {
        prop: 'rawDefinition',
        label: 'Definition',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 5
      },
      {
        prop: 'ruleSetRefsLabel',
        label: 'Rule set',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 2
      },
      {
        prop: 'jurisdictionsLabel',
        label: 'Jurisdiction',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2
      },
      {
        prop: 'ownerLabel',
        label: 'Owner',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2
      },
      {
        prop: 'categoriesLabel',
        label: 'Category',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: true,
        size: 2
      },
      {
        prop: 'isPrivate',
        label: 'Privacy',
        transformValue: value => ConceptTableUtils.getPrivacyIcon(value),
        tooltip: value => ConceptTableUtils.getPrivacyToolTip(value),
        sortable: true,
        extraClasses: 'center-aligned',
        size: 1.5
      },
      {
        prop: 'state',
        label: 'Status',
        transformValue: value => ConceptTableUtils.getStatusLabel(value),
        tooltip: value => value,
        sortable: true,
        extraClasses: 'center-aligned',
        size: 2
      }
    ];
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first()).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }

  private remove(actionInput: MenuActionInput): Observable<boolean> {
    const action = this.showRemoveModal(actionInput);
    action.pipe(first()).subscribe((result: boolean) => {
      if (result) {
        this.notificationService.showSimpleAlert(`All changes have been successfully saved`, 'success');
        this.table?.clearSelection();
        this.updateData();
      }
    });
    return action;
  }

  private showRemoveModal(actionInput: MenuActionInput) {
    const dialogRef = this.dialog.open(RemoveFromMyCollectionModalComponent, {
      ...RemoveFromMyCollectionModalComponent.modalConfig,
      data: {
        excludedIds: actionInput.exclusions,
        selectedIds: actionInput.selections
      }
    });
    return dialogRef.afterClosed().pipe(first());
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  private initSubscriptions() {
    this.store
      .select(CollectionsState.searchCollections)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.data = results));

    this.store
      .select(CollectionsState.totalSearchResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalData = results));

    this.store
      .select(CollectionsState.isLoadingSearchCollections)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.isLoading$.next(result);
      });
  }

  onFetch(searchCriteria: SearchCriteria) {
    this.searchCriteria = searchCriteria;
    this.updateData();
  }

  public idFetcher = (value: any) => {
    return value.id;
  };

  private updateData() {
    const request: SearchConceptsByMyCollectionRequest = {
      page: this.searchCriteria.page,
      pageSize: this.searchCriteria.pageSize,
      sortBy: CollectionsMapper.mapToSortBy(this.searchCriteria.sortBy),
      sortDirection: CollectionsMapper.mapToSortOrder(this.searchCriteria.sortOrder),
      selectedIds: this.searchCriteria.selectedIds,
      excludedIds: this.searchCriteria.excludedIds
    };
    this.store.dispatch(new SetMyCollectionsData(request));
  }

  public downloadData(_searchCriteria: SearchCriteria) {
    this.store
      .dispatch(
        new ExportTerms({
          sortBy: this.searchCriteria.sortBy,
          sortDirection: this.searchCriteria.sortOrder,
          selectedIds: this.searchCriteria.selectedIds,
          excludedIds: this.searchCriteria.excludedIds
        })
      )
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert('Collection list downloaded successfully', 'success');
      });
  }

  onSelect(row) {
    this.router.navigate(['concept', 'term-editor'], {
      queryParams: { conceptId: row.id }
    });
  }

  saveToStorageStore = (data: StorageData) => {
    this.store.dispatch(new SetCollectionsStorageData(data));
  };

  loadFromStorageStore = (): StorageData => {
    return this.store.selectSnapshot(CollectionsState.storageData);
  };

  ngAfterViewInit(): void {
    this.table?.triggerOnFetch();
  }
}
