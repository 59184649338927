<div class="compare-view-wrapper">
  <apx-data-table
    [entity]="'Term'"
    [data]="data"
    [totalData]="totalData"
    [columns]="columns"
    [filters]="filters"
    [pageSize]="PAGE_SIZE"
    [noResultsMessage]="'No terms found'"
    [idFetcher]="idFetcher"
    [initialLoadingState]="true"
    [fullHeightRows]="true"
    [showDownload]="true"
    [downloadPermission]="downloadPermission"
    [downloadTooltip]="'Download collection list'"
    [isDownloading$]="isDownloading$"
    [currentUserPermissions]="currentUserPermissions"
    [allowSelection]="false"
    [menuActions]="menuActions"
    [saveToStorage]="saveToStorageStore"
    [loadFromStorage]="loadFromStorageStore"
    [filterSaveToStorage]="filterSaveToStore"
    [filterLoadFromStorage]="filterLoadFromStore"
    (onFetch)="onFetch($event)"
    (onSelect)="onSelect($event)"
    (onDownload)="downloadData($event)"
  ></apx-data-table>
</div>
