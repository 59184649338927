import { FilterOption } from '../../models';
import { Filter, FILTER_TYPE } from '@apiax/web-commons';
import { ConceptUtils } from './concept.utils';
import { BehaviorSubject } from 'rxjs';
import { FilterOption as CommonsFilterOption } from '@apiax/web-commons/lib/filters/filters.interfaces';
import { RuleSetFilterOption } from '../../models/rule-set-filter-option';
import { isEmpty, isEqual } from 'lodash-es';

export class FilterUtils {
  static buildJurisdictionsFilter(jurisdictionOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'jurisdictions',
      type: 'jurisdictions',
      options: {
        searchable: true,
        values: jurisdictionOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Jurisdiction'
    };
  }

  static buildTranslationsFilter(translationOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'translations',
      type: 'translations',
      options: {
        searchable: true,
        values: translationOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Translation'
    };
  }

  static buildOrganizationFilter(
    organizationOptions: FilterOption[],
    label = 'Owner'
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'organizations',
      type: 'organizations',
      options: {
        searchable: true,
        values: organizationOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: label
    };
  }

  static buildStatusFilter(hasViewInactiveTerms: boolean): Filter {
    let status = [...ConceptUtils.STATUS_VALUES];

    if (!hasViewInactiveTerms) {
      status = status.filter(s => s.id !== ConceptUtils.INACTIVE_STATUS_VALUE);
    }

    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'status',
      type: 'status',
      options: {
        searchable: true,
        values: status.map(v => {
          return {
            value: v.id,
            label: v.label
          };
        })
      },
      name: 'Status'
    };
  }

  static buildRuleSetFamiliesFilter(ruleSetFamiliesOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'ruleSetFamilies',
      type: 'ruleSetFamilies',
      noReorder: true,
      options: {
        searchable: true,
        values: ruleSetFamiliesOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      selectionChange$: new BehaviorSubject<CommonsFilterOption[]>([]),
      name: 'Rule set family'
    };
  }

  static buildRuleSetsFilter(ruleSetFamiliesOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'ruleSets',
      type: 'ruleSets',
      noReorder: false,
      options: {
        searchable: true,
        values: ruleSetFamiliesOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      rebuildOptions$: new BehaviorSubject<boolean>(false),
      name: 'Rule set'
    };
  }

  static buildCategoriesFilter(categoriesOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'categories',
      type: 'categories',
      options: {
        searchable: true,
        values: categoriesOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Category'
    };
  }

  static buildDateRangeFilter(): Filter {
    let values = [];
    return {
      type: 'date',
      name: 'date',
      label: 'Enter a date range',
      property: 'dateRange',
      controlType: FILTER_TYPE.DATE_RANGE,
      options: {
        searchable: false,
        values: values
      }
    };
  }

  static buildEmployeesFilter(categoriesOptions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'userIds',
      type: 'userIds',
      options: {
        searchable: true,
        values: categoriesOptions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Employee'
    };
  }

  static buildDocumentTypesFilter(documentTypes: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'documentTypes',
      type: 'documentTypes',
      options: {
        values: documentTypes.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Document Type'
    };
  }

  static buildDocumentActivationStatusFilter(
    documentActivationStatus: FilterOption[]
  ): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'documentActivationStatus',
      type: 'documentActivationStatus',
      options: {
        values: documentActivationStatus.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Activation Status'
    };
  }

  static buildDocumentVersionsFilter(documentVersions: FilterOption[]): Filter {
    return {
      controlType: FILTER_TYPE.SELECT,
      property: 'documentVersions',
      type: 'documentVersions',
      noReorder: true,
      options: {
        searchable: true,
        values: documentVersions.map(j => {
          return {
            value: j.id,
            label: j.name
          };
        })
      },
      name: 'Version'
    };
  }

  static rebuildRuleSetFilter(
    selectedRuleSetFamilyFilterOptions: CommonsFilterOption[],
    ruleSets: RuleSetFilterOption[],
    ruleSetsFilter: Filter
  ) {
    const selectedRuleSetFamilies = isEmpty(selectedRuleSetFamilyFilterOptions)
      ? ruleSets
      : ruleSets.filter(rs =>
          selectedRuleSetFamilyFilterOptions.find(
            selectedRuleSetFamily => selectedRuleSetFamily.value === rs.ruleSetFamilyId
          )
        );
    const ruleSetsFilterAux = FilterUtils.buildRuleSetsFilter(selectedRuleSetFamilies);
    if (!isEqual(ruleSetsFilter.options.values, ruleSetsFilterAux.options.values)) {
      ruleSetsFilter.options.values = ruleSetsFilterAux.options.values;
      ruleSetsFilter.rebuildOptions$.next(true);
    } else {
      ruleSetsFilter.rebuildOptions$.next(false);
    }
  }
}
