import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Column,
  Filter,
  FilterData,
  MenuAction,
  NotificationService,
  SearchCriteria,
  StorageData
} from '@apiax/web-commons';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { Activity, AuthorizationService } from 'shared/core';
import { first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isEmpty } from 'lodash-es';
import { FilterUtils } from '../../../../../domain/utils/filter.utils';
import { RuleSetFilterOption } from '../../../../../models/rule-set-filter-option';
import {
  ExportCollectionsCompareView,
  ResetCollectionsCompareViewData,
  SearchCollectionsCompareView,
  SetCollectionsCompareViewFilterStorageData,
  SetCollectionsCompareViewStorageData
} from '../../../../../domain/stores/collections-compare-view/collections-compare-view.action';
import {
  CollectionsCompareViewState
} from '../../../../../domain/stores/collections-compare-view/collections-compare-view.state';
import {
  CollectionsCompareViewFiltersState
} from '../../../../../domain/stores/collections-compare-view-filters/collections-compare-view-filters.state';
import { ConceptValuesForTranslationWeb } from '../../../../../models/concept-values-for-translation-web';
import {
  LoadCollectionsCompareViewFilterOptions,
  ResetCollectionsCompareViewFiltersData
} from '../../../../../domain/stores/collections-compare-view-filters/collections-compare-view-filters.action';
import { GoBackType, SetGoBack } from '../../../../../domain/stores/taxonomy-concept';
import { ConceptUtils } from '../../../../../domain/utils/concept.utils';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CollectionsState } from '../../../../../domain/stores/collections/collections.state';

@UntilDestroy()
@Component({
  selector: 'app-collections-compare-view',
  templateUrl: './collections-compare-view.component.html',
  styleUrl: './collections-compare-view.component.scss'
})
export class CollectionsCompareViewComponent implements OnInit {
  private collectionId: string;
  public data: ConceptValuesForTranslationWeb[] = [];
  public totalData = 0;
  public readonly PAGE_SIZE = 10;
  public page: number = 0;
  public currentUserPermissions = new Map<Activity, boolean>();

  public filters: Filter[];
  public columns: Column[];

  public menuActions: MenuAction[] = [
    {
      permissions: [],
      icon: 'compare',
      iconClass: 'material-symbols-outlined',
      cssClass: 'plain-action primary-plain',
      title: 'Exit compare view',
      tooltip: 'Exit compare view',
      requiresSelectedRows: false,
      actionFunction: () => {
        return this.exitCompareView();
      }
    }
  ];

  public downloadPermission = Activity.MyCollectionsAccess;
  public isDownloading$ = new BehaviorSubject(false);

  private conceptUtils: ConceptUtils;

  constructor(
    location: Location,
    dialog: MatDialog,
    private router: Router,
    private authorizationService: AuthorizationService,
    private notificationService: NotificationService,
    private store: Store
  ) {
    this.conceptUtils = new ConceptUtils(router, dialog, location, store);

    this.buildColumns();
    this.getCurrentUserPermissions();
  }

  private buildColumns() {
    this.columns = [
      {
        prop: 'term',
        label: 'Term',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 1.5
      },
      {
        prop: 'jurisdictionsLabel',
        label: 'Jurisdiction',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 1
      },
      {
        prop: 'translationLabel',
        label: 'Translation',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 1
      },
      {
        prop: 'rawDefinition',
        label: 'Definition',
        transformValue: value => (!isEmpty(value) ? value : '-'),
        sortable: false,
        size: 4
      }
    ];
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions
      .pipe(untilDestroyed(this))
      .subscribe((permissions: Map<Activity, boolean>) => {
        this.currentUserPermissions = permissions;
      });
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  private loadFilterOptions() {
    if (!this.store.selectSnapshot(CollectionsCompareViewFiltersState.areFiltersLoaded)) {
      this.store
        .dispatch(new LoadCollectionsCompareViewFilterOptions(this.collectionId))
        .pipe(untilDestroyed(this))
        .subscribe(() => this.buildFilters());
    } else {
      this.buildFilters();
    }
  }

  private buildFilters() {
    combineLatest([
      this.store.select(CollectionsCompareViewFiltersState.ruleSetFamilyOptions),
      this.store.select(CollectionsCompareViewFiltersState.ruleSetOptions),
      this.store.select(CollectionsCompareViewFiltersState.ownerOptions),
      this.store.select(CollectionsCompareViewFiltersState.jurisdictionOptions),
      this.store.select(CollectionsCompareViewFiltersState.translationOptions),
      this.store.select(CollectionsCompareViewFiltersState.categoryOptions)
    ])
      .pipe(first())
      .subscribe(([ruleSetFamilies, ruleSets, owners, jurisdictions, translations, categories]) => {
        const ruleSetFamiliesFilter = FilterUtils.buildRuleSetFamiliesFilter(ruleSetFamilies);
        const ruleSetsFilter = FilterUtils.buildRuleSetsFilter(ruleSets);
        this.initReactRuleSetToRuleSetFamiliesChanges(ruleSetFamiliesFilter, ruleSets, ruleSetsFilter);

        this.filters = [
          ruleSetFamiliesFilter,
          ruleSetsFilter,
          FilterUtils.buildOrganizationFilter(owners),
          FilterUtils.buildJurisdictionsFilter(jurisdictions),
          FilterUtils.buildTranslationsFilter(translations),
          FilterUtils.buildCategoriesFilter(categories)
        ];
      });
  }

  private initReactRuleSetToRuleSetFamiliesChanges(
    ruleSetFamiliesFilter: Filter,
    ruleSets: RuleSetFilterOption[],
    ruleSetsFilter: Filter
  ) {
    ruleSetFamiliesFilter.selectionChange$.pipe(untilDestroyed(this)).subscribe(selectedRuleSetFamilyFilterOptions => {
      FilterUtils.rebuildRuleSetFilter(selectedRuleSetFamilyFilterOptions, ruleSets, ruleSetsFilter);
    });
  }

  private initSubscriptions() {
    this.store
      .select(CollectionsCompareViewState.collectionId)
      .pipe(first())
      .subscribe((collectionId: string) => {
        this.collectionId = collectionId ? collectionId : this.store.selectSnapshot(CollectionsState.selectedCollection);
        this.loadFilterOptions();
      });

    this.store
      .select(CollectionsCompareViewState.searchResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.data = results));

    this.store
      .select(CollectionsCompareViewState.totalResults)
      .pipe(untilDestroyed(this))
      .subscribe(results => (this.totalData = results));
  }

  private resetData() {
    this.store.dispatch(new ResetCollectionsCompareViewData());
    this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
  }

  public onFetch(searchCriteria: SearchCriteria) {
    if (this.collectionId) {
      this.store.dispatch(
        new SearchCollectionsCompareView({
          collectionId: this.collectionId,
          searchCriteria: searchCriteria
        })
      );
    } else {
      this.data = [];
      this.totalData = 0;
    }
  }

  public idFetcher = (value: any) => {
    return value.id;
  };

  public downloadData(searchCriteria: SearchCriteria) {
    this.store
      .dispatch(
        new ExportCollectionsCompareView({
          collectionId: this.collectionId,
          searchCriteria: searchCriteria
        })
      )
      .pipe(first())
      .subscribe(() => {
        this.isDownloading$.next(false);
        this.notificationService.showSimpleAlert('Collection list downloaded successfully', 'success');
      });
  }

  exitCompareView(): Observable<boolean> {
    this.resetData();

    return from(
      this.router.navigate(['my-collections']).then(() => {
        this.store.dispatch(new SetGoBack({ type: GoBackType.MY_COLLECTIONS }));
      })
    ).pipe(switchMap(() => of(true)));
  }

  onSelect(row) {
    if (row.translationCode === 'en') {
      this.conceptUtils.navigateToConceptTermEditor(row.conceptId);
    } else {
      this.conceptUtils.navigateToConceptTermEditorTranslate(row.conceptId, row.translationCode);
    }
    this.store.dispatch(new SetGoBack({ type: GoBackType.MY_COLLECTIONS_COMPARE_VIEW }));
    this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
  }

  saveToStorageStore = (data: StorageData) => {
    this.store.dispatch(new SetCollectionsCompareViewStorageData(data));
  };

  loadFromStorageStore = (): StorageData => {
    return this.store.selectSnapshot(CollectionsCompareViewState.storageData);
  };

  filterSaveToStore = (data: Map<string, FilterData>) => {
    this.store.dispatch(new SetCollectionsCompareViewFilterStorageData(data));
  };

  filterLoadFromStore: () => Map<string, FilterData> = (): Map<string, FilterData> => {
    const filterStorageData = this.store.selectSnapshot(CollectionsCompareViewState.filterStorageData);
    return new Map(Object.entries(filterStorageData));
  };
}
