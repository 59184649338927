import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isEmpty } from 'lodash-es';
import { filter } from 'rxjs/operators';
import { TaxonomyUtilsService } from '../../../domain/services/taxonomy-utils.service';
import {
  ClearTaxonomyConceptState,
  GoBackType,
  ResetConcept,
  SetGoBack
} from '../../../domain/stores/taxonomy-concept';
import { ClearOrganizationState } from '../../../domain/stores/organizations/organizations.action';
import { ClearConceptLLMState } from '../../../domain/stores/llm-term-definitions/concept-llm.action';
import {
  ResetCollectionsCompareViewData
} from '../../../domain/stores/collections-compare-view/collections-compare-view.action';
import {
  ResetCollectionsCompareViewFiltersData
} from '../../../domain/stores/collections-compare-view-filters/collections-compare-view-filters.action';

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-index',
  templateUrl: './taxonomy-index.component.html',
  styleUrls: ['./taxonomy-index.component.scss']
})
export class TaxonomyIndexComponent implements OnInit {
  private tabIndexRouterMapping: { [key: number]: () => void } = {};
  private routerUrlIndexTabMapping: { [key: string]: number } = {};

  constructor(
    private router: Router,
    private store: Store,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.router.events
      .pipe(
        filter(val => val instanceof ActivationEnd),
        untilDestroyed(this)
      )
      .subscribe((val: any) => {
        this.buildRouterMappings();
        const urlParts = (val.snapshot as ActivatedRouteSnapshot).url;

        if (!isEmpty(urlParts)) {
          const path = urlParts[urlParts.length - 1].path;
          taxonomyUtilsService.rootSelectedTabIndex = this.getTabIndex(path);
        }
      });
  }

  public tabChanged(event: MatTabChangeEvent) {
    this.executeMappedRoute(event.index);
  }

  public executeMappedRoute(index: number) {
    this.tabIndexRouterMapping[index]();
  }

  public getTabIndex(path: string) {
    let routerUrlIndexTabMappingElement = this.routerUrlIndexTabMapping[path];
    return routerUrlIndexTabMappingElement ? routerUrlIndexTabMappingElement : -1;
  }

  public buildRouterMappings() {
    this.tabIndexRouterMapping = {};
    let i = 0;
    this.tabIndexRouterMapping[i] = () => {
      this.router.navigate(['search-terms']).then(() => {
        this.store.dispatch(new SetGoBack({ type: GoBackType.SEARCH_TERMS }));
        this.store.dispatch(new ResetCollectionsCompareViewData());
        this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
      });
    };
    this.routerUrlIndexTabMapping['search-terms'] = i;
    i++;

    if (this.taxonomyUtilsService.hasTaxonomyMyCollectionAccess()) {
      this.tabIndexRouterMapping[i] = () => {
        this.router.navigate(['my-collections']).then(() => {
          this.store.dispatch(new SetGoBack({ type: GoBackType.MY_COLLECTIONS }));
        });
      };
      this.routerUrlIndexTabMapping['my-collections'] = i;
      i++;
    }

    this.tabIndexRouterMapping[i] = () => {
      this.router.navigate(['search-on-rules']).then(() => {
        this.store.dispatch(new SetGoBack({ type: GoBackType.SEARCH_ON_RULES }));
        this.store.dispatch(new ResetCollectionsCompareViewData());
        this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
      });
    };
    this.routerUrlIndexTabMapping['search-on-rules'] = i;
    i++;

    if (this.taxonomyUtilsService.hasTaxonomyLatestChangesAccess()) {
      this.tabIndexRouterMapping[i] = () => {
        this.router.navigate(['latest-changes']).then(() => {
          this.store.dispatch(new SetGoBack({ type: GoBackType.LATEST_CHANGES }));
          this.store.dispatch(new ResetCollectionsCompareViewData());
          this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
        });
      };
      this.routerUrlIndexTabMapping['latest-changes'] = i;
      i++;
    }

    if (this.taxonomyUtilsService.hasTaxonomyExportDataAccess()) {
      this.tabIndexRouterMapping[i] = () => {
        this.router.navigate(['export-data']).then(() => {
          this.store.dispatch(new SetGoBack({ type: GoBackType.EXPORT_DATA }));
          this.store.dispatch(new ResetCollectionsCompareViewData());
          this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
        });
      };
      this.routerUrlIndexTabMapping['export-data'] = i;
      i++;
    }

    if (this.taxonomyUtilsService.hasTechnicalAdministration()) {
      this.tabIndexRouterMapping[i] = () => {
        this.router.navigate(['control-panel']).then(() => {
          this.store.dispatch(new SetGoBack({ type: GoBackType.CONTROL_PANEL }));
          this.store.dispatch(new ResetCollectionsCompareViewData());
          this.store.dispatch(new ResetCollectionsCompareViewFiltersData());
        });
      };
      this.routerUrlIndexTabMapping['control-panel'] = i;
      i++;
    }
  }

  public onCreate() {
    return () => {
      this.router.navigate(['concept', 'term-editor']);
    };
  }

  ngOnInit(): void {
    const currentPath = this.router.url.split('/')[1];
    switch (currentPath) {
      case 'search-terms':
        this.store.dispatch(new SetGoBack({ type: GoBackType.SEARCH_TERMS }));
        this.clearStores();
        break;
      case 'my-collections':
        const currentPath = this.router.url.split('/')[2];
        if(currentPath === 'compare-view') {
          this.store.dispatch(new SetGoBack({ type: GoBackType.MY_COLLECTIONS_COMPARE_VIEW }));
          this.clearStores();
          break;
        }
        this.store.dispatch(new SetGoBack({ type: GoBackType.MY_COLLECTIONS }));
        this.clearStores();
        break;
      case 'search-on-rules':
        this.store.dispatch(new SetGoBack({ type: GoBackType.SEARCH_ON_RULES }));
        this.clearStores();
        break;
      case 'latest-changes':
        this.store.dispatch(new SetGoBack({ type: GoBackType.LATEST_CHANGES }));
        this.clearStores();
        break;
      case 'export-data':
        this.store.dispatch(new SetGoBack({ type: GoBackType.EXPORT_DATA }));
        this.clearStores();
        break;
      case 'control-panel':
        this.store.dispatch(new SetGoBack({ type: GoBackType.CONTROL_PANEL }));
        this.clearStores();
        break;
    }
  }

  private clearStores() {
    this.store.dispatch(new ResetConcept());
    this.store.dispatch(new ClearTaxonomyConceptState());
    this.store.dispatch(new ClearOrganizationState());
    this.store.dispatch(new ClearConceptLLMState());
  }
}
