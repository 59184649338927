import { SearchCriteria } from '@apiax/web-commons';
import { StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConceptApiService } from '../../../../../generated/v3';
import { TaxonomySearchOnRulesStateModel } from '../taxonomy-search-on-rules-state.service';
import { TaxonomySearchOnRulesMapper } from '../../../mappers/taxonomy-search-on-rules-mapper';
import { FileUtils } from '../../../utils/file.utils';
import { SetSearchOnRulesStorageData, SetSearchOnRulesStorageFilterData } from '../taxonomy-search-on-rules.action';

export class TaxonomySearchOnRulesStateUseCases {
  constructor(private taxonomyV3ApiService: ConceptApiService) {
  }

  // Search

  public searchOnRules(context: StateContext<TaxonomySearchOnRulesStateModel>, searchCriteria: SearchCriteria) {
    context.patchState({
      isLoadingSearch: true
    });
    if (!searchCriteria.searchFilter && Object.keys(searchCriteria.contentFilters).length === 0) {
      context.patchState({
        searchResults: [],
        isLoadingSearch: false,
        totalResults: 0
      });
      return;
    }

    return this.taxonomyV3ApiService
      .conceptSearchOnRules(TaxonomySearchOnRulesMapper.mapToConceptRuleSearchRequest(searchCriteria))
      .pipe(
        tap(response => {
          context.patchState({
            searchResults: response.list.map(result =>
              TaxonomySearchOnRulesMapper.mapToConceptSearchOnRulesResult(result)
            ),
            isLoadingSearch: false,
            totalResults: response.total
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  // Export
  public searchOnRulesExport(context: StateContext<TaxonomySearchOnRulesStateModel>, searchCriteria: SearchCriteria) {
    if (!searchCriteria.searchFilter && Object.keys(searchCriteria.contentFilters).length === 0) {
      return;
    }

    return this.taxonomyV3ApiService
      .conceptSearchOnRulesExport(TaxonomySearchOnRulesMapper.mapToConceptSearchOnRulesExportResult(searchCriteria))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'searchOnRulesList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  setStorageData(context: StateContext<TaxonomySearchOnRulesStateModel>, action: SetSearchOnRulesStorageData) {
    context.patchState({
      storageData: action.storageData
    });
  }

  setFilterStorageData(context: StateContext<TaxonomySearchOnRulesStateModel>, action: SetSearchOnRulesStorageFilterData) {
    // web-commons is returning a Map<string, FilterData> however NGXS does not support MAP. So we need to convert it to object
    context.patchState({
      filterStorageData: Object.fromEntries(action.storageData)
    });
  }
}
