import { LatestChangeApiService } from 'generated/v3';
import { StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TaxonomyLatestChangesMapper } from '../../../mappers/taxonomy-latest-changes-mapper';
import { TaxonomyLatestChangesStateModel } from '../taxonomy-latest-changes.state';
import { FileUtils } from '../../../utils/file.utils';
import {
  ExportLatestChanges,
  SearchLatestChanges,
  SetTaxonomyLatestChangesStorageData,
  SetTaxonomyLatestChangesStorageFilterData
} from '../taxonomy-latest-changes.action';

export class TaxonomyLatestChangesStateUseCases {
  constructor(private latestChangeApiService: LatestChangeApiService) {
  }

  search(context: StateContext<TaxonomyLatestChangesStateModel>, action: SearchLatestChanges) {
    context.patchState({
      isLoadingLatestChangesSearch: true
    });
    return this.latestChangeApiService
      .getLatestChanges(TaxonomyLatestChangesMapper.mapToLatestChangeSearchRequest(action.searchCriteria))
      .pipe(
        tap(response => {
          context.patchState({
            latestChangesSearchResults: response.changes.map(result =>
              TaxonomyLatestChangesMapper.mapToLatestChangeModel(result)
            ),
            isLoadingLatestChangesSearch: false,
            latestChangesTotalResults: response.total
          });
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public export(action: ExportLatestChanges, _context: StateContext<TaxonomyLatestChangesStateModel>) {
    return this.latestChangeApiService
      .latestChangesExport(TaxonomyLatestChangesMapper.mapToLatestChangeExportRequest(action.searchCriteria))
      .pipe(
        tap(response => {
          FileUtils.downloadFile(response, 'latestChangesList');
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  setStorageData(context: StateContext<TaxonomyLatestChangesStateModel>, action: SetTaxonomyLatestChangesStorageData) {
    context.patchState({
      storageData: action.storageData
    });
  }


  setFilterStorageData(context: StateContext<TaxonomyLatestChangesStateModel>, action: SetTaxonomyLatestChangesStorageFilterData) {
    // web-commons is returning a Map<string, FilterData> however NGXS does not support MAP. So we need to convert it to object
    context.patchState({
      filterStorageData: Object.fromEntries(action.storageData)
    });
  }
}
