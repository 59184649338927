import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ChipsWithAdditionOption,
  CountrySliderOption,
  NotificationService,
  TemplatePickerOption
} from '@apiax/web-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { cloneDeep, isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { getConfig } from 'shared/app';
import { Activity, AuthorizationService, PhotoService } from 'shared/core';
import { ErrorsDao } from '../../../../../domain/daos/errors.dao';
import { PhotoDAO } from '../../../../../domain/daos/photo.dao';
import { OrganizationsMapper } from '../../../../../domain/mappers/organizations-mapper';
import { TaxonomyConceptMapper } from '../../../../../domain/mappers/taxonomy-concept-mapper';
import { TaxonomyUtilsService } from '../../../../../domain/services/taxonomy-utils.service';
import {
  LoadAccessibleOwners,
  LoadProductRuleSets
} from '../../../../../domain/stores/organizations/organizations.action';
import { OrganizationsState } from '../../../../../domain/stores/organizations/organizations.state';
import {
  AddConceptTranslation,
  CheckDeployedUsages,
  DeleteConceptTranslation,
  LoadConcept,
  TaxonomyConceptState,
  UpdateConcept,
  UpdateIsDirty,
  UpdateLanguageTermAndDefinition,
  UpdateSelectedLanguage
} from '../../../../../domain/stores/taxonomy-concept';
import { ConceptUtils } from '../../../../../domain/utils/concept.utils';
import { Concept, ConceptState, ProductRuleSet } from '../../../../../models';
import {
  DeployedUsageModalComponent,
  DeployedUsageModalData
} from '../../../../shared-components/modals/deployed-usage/deployed-usage-modal.component';
import {
  OutdatedConceptModalComponent,
  OutdatedConceptModalData
} from '../../../../shared-components/modals/outdated-concept/outdated-concept-modal.component';
import {
  TranslationLanguageModalComponent,
  TranslationLanguageModalData
} from '../../../../shared-components/modals/translation-language/translation-language-modal.component';

enum ConceptForm {
  Id = 'id',
  Term = 'term',
  TermId = 'termId',
  Owner = 'owner',
  RuleSets = 'ruleSets',
  Jurisdictions = 'jurisdictions',
  Categories = 'categories',
  Privacy = 'privacy',
  Status = 'status',
  Definition = 'definition',
  Slider = 'slider'
}

@UntilDestroy()
@Component({
  selector: 'app-taxonomy-term-editor-translation',
  templateUrl: './taxonomy-term-editor-translation.component.html',
  styleUrls: ['./taxonomy-term-editor-translation.component.scss']
})
export class TaxonomyTermEditorTranslationComponent implements OnInit, OnDestroy {
  private readonly DEFAULT_LANGUAGE = 'en';

  public currentUserPermissions = new Map<Activity, boolean>();

  public isLoading$: Observable<boolean>;

  public isLoadingOwners$: Observable<boolean>;
  public isLoadingProductRuleSets$: Observable<boolean>;
  public isLoadingConcept$: Observable<boolean>;
  public conceptUtils: ConceptUtils;

  private concept: Concept;

  public form = new UntypedFormGroup({});
  public formNames = ConceptForm;

  public availableOwners$: Observable<TemplatePickerOption[]>;
  private availableOwners: TemplatePickerOption[];

  public languageOptions: CountrySliderOption[];
  public selectSliderAdd = false;

  public availablePrivacyValues$: Observable<TemplatePickerOption[]>;
  private availablePrivacyValues: TemplatePickerOption[];

  public availableStatusValues$: Observable<TemplatePickerOption[]>;
  private availableStatusValues: TemplatePickerOption[];

  private availableProductRuleSets: Map<string, ProductRuleSet[]>;

  private selectedRuleSets: ChipsWithAdditionOption[] = [];

  private photoDAO: PhotoDAO;
  private isLanguagesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOwnersLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isProductRuleSetLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get privacyTooltip(): string {
    return ConceptUtils.privacyTooltip(this.form.get(ConceptForm.Privacy).value?.id);
  }

  public get statusTooltip(): string {
    return ConceptUtils.statusTooltip(this.form.get(ConceptForm.Status).value?.id);
  }

  constructor(
    photoService: PhotoService,
    router: Router,
    location: Location,
    private authorizationService: AuthorizationService,
    private store: Store,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    public taxonomyUtilsService: TaxonomyUtilsService
  ) {
    this.photoDAO = new PhotoDAO(photoService);

    this.conceptUtils = new ConceptUtils(router, dialog, location, store);

    this.getCurrentUserPermissions();
    this.initLoading();
  }

  ngOnInit() {
    this.initForm();
    this.subscribeConcept();
    this.dispatchStoreActions();
    this.subscribeFormControlsValueChanges();
    this.subscribeAvailableLanguagesOptions();

    combineLatest([this.isLanguagesLoaded$, this.isOwnersLoaded$, this.isProductRuleSetLoaded$])
      .pipe(
        untilDestroyed(this),
        filter(([isLanguagesLoaded, isOwnersLoaded, isProductRuleSetLoaded]) => {
          return isLanguagesLoaded && isOwnersLoaded && isProductRuleSetLoaded;
        })
      )
      .subscribe(() => {
        this.setForm();
      });
    this.subscribeLanguageSelection();
    this.checkIfConceptIsDirty();
  }

  ngOnDestroy() {
    this.store.dispatch(
      new UpdateLanguageTermAndDefinition({
        term: this.form.get(ConceptForm.Term).value,
        definition: this.form.get(ConceptForm.Definition).value
      })
    );
    this.store.dispatch(new UpdateIsDirty({ isDirty: this.form.dirty }));
  }

  private getCurrentUserPermissions() {
    this.authorizationService.currentUserPermissions.pipe(first()).subscribe(permissions => {
      this.currentUserPermissions = permissions;
    });
  }

  private initLoading() {
    this.isLoadingOwners$ = this.store.select(OrganizationsState.isLoadingAccessibleOwners);
    this.isLoadingProductRuleSets$ = this.store.select(OrganizationsState.isLoadingProductRuleSets);
    this.isLoadingConcept$ = this.store.select(TaxonomyConceptState.isLoadingConcept);

    this.isLoading$ = combineLatest([
      this.isLoadingOwners$,
      this.isLoadingProductRuleSets$,
      this.isLoadingConcept$,
      this.isLanguagesLoaded$
    ]).pipe(
      untilDestroyed(this),
      map(([isLoadingOwners, isLoadingProductRuleSets, isLoadingConcept, isLanguagesLoaded]) => {
        return isLoadingOwners || isLoadingProductRuleSets || isLoadingConcept || !isLanguagesLoaded;
      })
    );
  }

  private subscribeConcept() {
    this.concept = this.store.selectSnapshot(TaxonomyConceptState.concept);
    this.languageOptions = ConceptUtils.convertToCountrySliderOption(
      this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)?.availableTranslationLanguages
    );
    this.isLanguagesLoaded$.next(true);
  }

  private subscribeAvailableLanguagesOptions() {
    this.store
      .select(TaxonomyConceptState.getConceptTranslations)
      .pipe(untilDestroyed(this))
      .subscribe(translations => {
        const availableLanguages = translations?.availableTranslationLanguages;
        this.languageOptions = ConceptUtils.convertToCountrySliderOption(availableLanguages);
      });
  }

  private subscribeLanguageSelection() {
    this.form
      .get(ConceptForm.Slider)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(v => {
        const conceptId = this.store.selectSnapshot(TaxonomyConceptState.conceptId);
        this.store.dispatch(new UpdateSelectedLanguage({ selectedLanguage: v.id }));

        if (v.id === this.DEFAULT_LANGUAGE && !!conceptId) {
          this.conceptUtils.navigateToConceptTermEditor(conceptId);
        } else {
          this.conceptUtils.replaceUrlToConceptTermEditorTranslate(conceptId, v.id);
          const availableTranslations = this.store.selectSnapshot(
            TaxonomyConceptState.getConceptTranslations
          )?.availableConceptTranslations;
          this.form.get(ConceptForm.Term).setValue(availableTranslations[v.id]?.term);
          this.form.get(ConceptForm.Definition).setValue(availableTranslations[v.id]?.definition);
        }
      });
  }

  private initForm() {
    this.form = new UntypedFormGroup({});
    this.form.addControl(ConceptForm.Id, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Term, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(
      ConceptForm.TermId,
      new UntypedFormControl(undefined, [Validators.required, Validators.pattern(ConceptUtils.TERM_ID_PATTERN)])
    );
    this.form.addControl(ConceptForm.Owner, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.RuleSets, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Jurisdictions, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Categories, new UntypedFormControl(undefined, []));
    this.form.addControl(ConceptForm.Privacy, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Status, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Definition, new UntypedFormControl(undefined, Validators.required));
    this.form.addControl(ConceptForm.Slider, new UntypedFormControl());
  }

  private setForm() {
    this.form.get(ConceptForm.Id).setValue(this.concept.id);
    this.form.get(ConceptForm.Id).disable();
    this.form.get(ConceptForm.TermId).setValue(this.concept.termId);
    this.form.get(ConceptForm.TermId).disable();
    this.setTermStatus();
    this.setDefinitionStatus();
    this.setOwnerControlOnViewUpdate();
    this.setRuleSetsControlOnViewUpdate();
    this.setJurisdictionsControlOnViewUpdate();
    this.setCategoriesControlOnViewUpdate();
    this.setPrivacyControlOnViewUpdate();
    this.setStatusControlOnViewUpdate();
    this.setTranslationLanguagesOptions();
  }

  private dispatchStoreActions() {
    this.loadOwners();
    this.loadProductRuleSets();
    this.loadPrivacyValues();
    this.loadStatusValues();
  }

  private loadOwners() {
    const ownersInStore = this.store.selectSnapshot(OrganizationsState.accessibleOwners);
    if (!ownersInStore?.length) {
      this.store
        .dispatch(new LoadAccessibleOwners())
        .pipe(first())
        .subscribe(() => {
          const owners = this.store.selectSnapshot(OrganizationsState.accessibleOwners);
          this.availableOwners = owners?.map(obj => {
            return {
              id: obj.id,
              label: obj.name,
              image: this.photoDAO.getPhotoUrl(obj.photoId)
            };
          });

          this.setOwnerControlOnCreate();
          this.isOwnersLoaded$.next(true);
        });
    } else {
      this.availableOwners = ownersInStore?.map(obj => {
        return {
          id: obj.id,
          label: obj.name,
          image: this.photoDAO.getPhotoUrl(obj.photoId)
        };
      });

      this.setOwnerControlOnViewUpdate();
      this.isOwnersLoaded$.next(true);
    }
  }

  private loadProductRuleSets() {
    let availableProductRuleSets;

    availableProductRuleSets = this.store.selectSnapshot(OrganizationsState.productRuleSets);

    if (!availableProductRuleSets?.size) {
      this.store
        .dispatch(new LoadProductRuleSets())
        .pipe(first())
        .subscribe(() => {
          availableProductRuleSets = this.store.selectSnapshot(OrganizationsState.productRuleSets);
          this.availableProductRuleSets = cloneDeep(availableProductRuleSets);

          this.setRuleSetsControlOnCreate();
          this.setJurisdictionsControlOnCreate();
          this.isProductRuleSetLoaded$.next(true);
        });
    } else {
      this.availableProductRuleSets = cloneDeep(availableProductRuleSets);

      this.setRuleSetsControlOnCreate();
      this.setJurisdictionsControlOnCreate();
      this.isProductRuleSetLoaded$.next(true);
    }
  }

  private loadPrivacyValues() {
    this.availablePrivacyValues = ConceptUtils.PRIVACY_VALUES;
    this.setPrivacyControlOnCreate();
  }

  private loadStatusValues() {
    this.availableStatusValues = ConceptUtils.STATUS_VALUES;
  }

  private setOwnerControlOnCreate() {
    if (!this.currentUserPermissions.get(Activity.AllCompaniesView)) {
      const value = this.availableOwners.find(
        obj => obj.id === this.authorizationService.currentUser.value.organizationId
      );

      this.form.get(ConceptForm.Owner).setValue(value);
      this.form.get(ConceptForm.Owner).disable();
    }

    this.availableOwners$ = of(this.availableOwners);
  }

  private setTermStatus() {
    this.taxonomyUtilsService.canUpdateConcept()
      ? this.form.get(ConceptForm.Term).enable({ emitEvent: false })
      : this.form.get(ConceptForm.Term).disable({ emitEvent: false });
  }

  private setDefinitionStatus() {
    this.taxonomyUtilsService.canUpdateConcept()
      ? this.form.get(ConceptForm.Definition).enable({ emitEvent: false })
      : this.form.get(ConceptForm.Definition).disable({ emitEvent: false });
  }

  private setOwnerControlOnViewUpdate() {
    const owner = this.availableOwners.find(obj => obj.id === this.concept.owner);

    this.form.get(ConceptForm.Owner).setValue(owner, { emitEvent: false });
    this.form.get(ConceptForm.Owner).disable();
  }

  private setRuleSetsControlOnCreate() {
    this.selectedRuleSets = [ConceptUtils.RULESET_ANY_SELECTED_VALUE_OPTION];

    this.form.get(ConceptForm.RuleSets).setValue([ConceptUtils.RULESET_ANY_VALUE_OPTION]);
    this.form.get(ConceptForm.RuleSets).disable();
  }

  private setRuleSetsControlOnViewUpdate() {
    const ruleSets = this.conceptUtils.mapRuleSets(this.availableProductRuleSets, this.concept.ruleSets);

    this.selectedRuleSets = ruleSets.selections;

    this.form.get(ConceptForm.RuleSets).setValue(ruleSets.newValues);
    this.form.get(ConceptForm.RuleSets).disable();
  }

  private setJurisdictionsControlOnCreate() {
    this.form.get(ConceptForm.Jurisdictions).setValue([ConceptUtils.JURISDICTION_GLOBAL_VALUE_OPTION]);
  }

  private setJurisdictionsControlOnViewUpdate() {
    const jurisdictions = this.conceptUtils.mapJurisdictions(this.availableProductRuleSets, this.concept.jurisdictions);

    this.form.get(ConceptForm.Jurisdictions).setValue(jurisdictions);
    this.form.get(ConceptForm.Jurisdictions).disable();
  }

  private setCategoriesControlOnViewUpdate() {
    // apx-chips is only updating values onInit
    // when the component is already created the OnInit does not run again
    // setTimeout will force to update the component
    setTimeout(() => {
      const categories = new Set(this.concept.categories);

      this.form.get(ConceptForm.Categories).setValue(categories);
      this.form.get(ConceptForm.Categories).disable();
    }, 5);
  }

  private setPrivacyControlOnCreate() {
    if (!this.isSelectedOwnerApiax()) {
      const value = this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PRIVATE_PRIVACY_VALUE);

      this.form.get(ConceptForm.Privacy).setValue(value);
      this.form.get(ConceptForm.Privacy).disable();
    } else {
      const value = this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PUBLIC_PRIVACY_VALUE);

      this.form.get(ConceptForm.Privacy).setValue(value);
      this.form.get(ConceptForm.Privacy).enable();
    }

    this.availablePrivacyValues$ = of(this.availablePrivacyValues);
  }

  private setPrivacyControlOnViewUpdate() {
    const privacy = this.concept.isPrivate
      ? this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PRIVATE_PRIVACY_VALUE)
      : this.availablePrivacyValues.find(obj => obj.id === ConceptUtils.PUBLIC_PRIVACY_VALUE);

    this.form.get(ConceptForm.Privacy).setValue(privacy);
    this.form.get(ConceptForm.Privacy).disable();
  }

  private setStatusControlOnViewUpdate() {
    let value;

    switch (this.concept.state) {
      case ConceptState.Active:
        value = this.availableStatusValues.find(obj => obj.id === ConceptUtils.ACTIVE_STATUS_VALUE);
        break;
      case ConceptState.Deprecated:
        value = this.availableStatusValues.find(obj => obj.id === ConceptUtils.DEPRECATED_STATUS_VALUE);
        break;
      case ConceptState.Inactive:
        value = this.availableStatusValues.find(obj => obj.id === ConceptUtils.INACTIVE_STATUS_VALUE);
        break;
    }

    this.form.get(ConceptForm.Status).setValue(value);
    this.form.get(ConceptForm.Status).disable();

    this.availableStatusValues$ = of(this.availableStatusValues);
  }

  private setTranslationLanguagesOptions() {
    this.store
      .select(TaxonomyConceptState.getConceptTranslations)
      .pipe(
        filter(r => !!r),
        first()
      )
      .subscribe(translations => {
        const selectedTranslationLanguage = translations?.selectedTranslationLanguage;
        const sliderSelectedOption = selectedTranslationLanguage
          ? {
            id: selectedTranslationLanguage.code,
            flagCode: selectedTranslationLanguage.country,
            label: selectedTranslationLanguage.name
          }
          : { id: 'en', label: '', flagCode: 'GBR' };
        this.form.get(ConceptForm.Slider).setValue(sliderSelectedOption, { emitEvent: false });
        this.form
          .get(ConceptForm.Term)
          .setValue(translations.availableConceptTranslations[selectedTranslationLanguage.code]?.term);
        this.form
          .get(ConceptForm.Definition)
          .setValue(translations.availableConceptTranslations[selectedTranslationLanguage.code]?.definition);
      });
  }

  private isSelectedOwnerApiax(): boolean {
    return OrganizationsMapper.isApiaxCompany(this.form.get(ConceptForm.Owner).value?.id);
  }

  private checkIfConceptIsDirty() {
    /**
     * When we change to term-editor we lose context.
     * so when we change to term-editor we need to save if the form is safe
     * we must check this state when we move between term-editor-translation and term-editor
     * */
    if (this.store.selectSnapshot(TaxonomyConceptState.isDirty)) {
      this.form.markAsDirty();
    }
  }

  private subscribeFormControlsValueChanges() {
    this.onTermValueChange();
    this.onDefinitionValueChange();
  }

  private onTermValueChange() {
    this.form
      .get(ConceptForm.Term)
      .valueChanges.pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((term: string) => {
        this.store.dispatch(new UpdateLanguageTermAndDefinition({ term: term }));
        this.store.dispatch(new UpdateIsDirty({ isDirty: this.form.dirty }));
      });
  }

  private onDefinitionValueChange() {
    this.form
      .get(ConceptForm.Definition)
      .valueChanges.pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((definition: string) => {
        if (this.form.get(ConceptForm.Definition).dirty) {
          this.store.dispatch(new UpdateLanguageTermAndDefinition({ definition: definition }));
        }
        this.store.dispatch(new UpdateIsDirty({ isDirty: this.form.dirty }));
      });
  }

  public ruleSetsAdditionalInfo = value => {
    return this.conceptUtils.ruleSetsAdditionalInfo(this.selectedRuleSets, value);
  };

  public ruleSetsAdditionalInfoTooltip = value => {
    return this.conceptUtils.ruleSetsAdditionalInfoTooltip(this.selectedRuleSets, value);
  };

  public onDeleteTranslation() {
    return (callback: () => void) => {
      this.store
        .dispatch(new DeleteConceptTranslation())
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            callback();
          })
        )
        .subscribe(() => {
          const selectedLanguageCode = this.store.selectSnapshot(
            TaxonomyConceptState.getConceptTranslations
          )?.selectedTranslationLanguage;
          this.form.get(ConceptForm.Slider).setValue({
            id: selectedLanguageCode.code,
            label: selectedLanguageCode.name,
            flagCode: selectedLanguageCode.code
          });
          this.form.markAsDirty();
          if (selectedLanguageCode.code === 'en') {
            this.store.dispatch(new UpdateIsDirty({ isDirty: this.form.dirty }));
            this.conceptUtils.navigateToConceptTermEditor(this.concept.id);
          }
        });
    };
  }

  public onSubmit() {
    return (callback: () => void) => {
      let updateActionObservable: Observable<any>;
      const translations = this.store.selectSnapshot(TaxonomyConceptState.concept).translations;
      const availableConceptTranslations = this.store.selectSnapshot(
        TaxonomyConceptState.getConceptTranslations
      ).availableConceptTranslations;
      const hasChangedTranslation = !isEqual(translations, availableConceptTranslations);

      if (
        hasChangedTranslation ||
        this.form.get(ConceptForm.Term).dirty ||
        this.form.get(ConceptForm.Definition).dirty ||
        this.store.selectSnapshot(TaxonomyConceptState.isTermDirty) ||
        this.store.selectSnapshot(TaxonomyConceptState.isDefinitionDirty)
      ) {
        updateActionObservable = this.checkDeployedAndUpdateAction();
      } else {
        updateActionObservable = this.store.dispatch(this.getUpdateConceptAction());
      }

      return updateActionObservable
        .pipe(
          catchError(err => {
            this.checkIssues(err.error);
            return EMPTY;
          }),
          finalize(() => {
            callback();
          })
        )
        .subscribe(result => {
          if (result) {
            this.notificationService.showSimpleAlert('Concept updated successfully', 'success');
            this.form.markAsUntouched();
            this.form.markAsPristine();
            const language = this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)
              ?.selectedTranslationLanguage?.code;
            this.conceptUtils.navigateToConceptTermEditorTranslate(this.concept.id, language);
          }
        });
    };
  }

  private checkDeployedAndUpdateAction() {
    let shouldRedirectToLatestDeployed = false;
    return this.store.dispatch(new CheckDeployedUsages(this.concept.id)).pipe(
      first(),
      switchMap(() => {
        const hasDeployedUsages = this.store.selectSnapshot(TaxonomyConceptState.hasDeployedUsages);
        if (hasDeployedUsages) {
          return this.openDeployedUsageModal().pipe(
            tap(value => {
              shouldRedirectToLatestDeployed = value;
            })
          );
        } else {
          return of(true);
        }
      }),
      switchMap(response => {
        if (response) {
          if (shouldRedirectToLatestDeployed) {
            window.open(`${getConfig('rulesWebUrl')}/latestDeployedDocuments?conceptId=${this.concept.id}`, '_blank');
          }
          return this.store.dispatch(this.getUpdateConceptAction());
        } else {
          return of(false);
        }
      })
    );
  }

  public openDeployedUsageModal() {
    const data: DeployedUsageModalData = {
      title: 'Warning',
      message:
        'The term you’re updating is being used in deployed versions of rules. If you want these changes to be reflected in those rules, new versions must be created.' +
        '<br><br>By continuing the term will be updated and you’ll be prompted to create new versions of the latest versions of deployed impacted rules.',
      actionLabel: 'Update term and continue'
    };

    const dialogRef = this.dialog.open(DeployedUsageModalComponent, {
      data: data,
      ...DeployedUsageModalComponent.DEFAULT_CONFIG
    });

    return dialogRef.afterClosed();
  }

  private getUpdateConceptAction() {
    const currentConcept = this.store.selectSnapshot(TaxonomyConceptState.concept);
    return new UpdateConcept({
      id: this.form.get(ConceptForm.Id).value,
      termId: this.form.get(ConceptForm.TermId).value,
      term: currentConcept.term,
      definition: currentConcept.definition,
      owner: this.form.get(ConceptForm.Owner).value.id,
      ruleSetFamilyList: TaxonomyConceptMapper.mapToGeneratedConceptRuleSets(this.selectedRuleSets),
      jurisdictions: TaxonomyConceptMapper.mapToGeneratedConceptJurisdictions(
        this.form.get(ConceptForm.Jurisdictions).value
      ),
      categories: TaxonomyConceptMapper.mapToGeneratedConceptCategories(this.form.get(ConceptForm.Categories).value),
      isPrivate: isEqual(this.form.get(ConceptForm.Privacy).value.id, ConceptUtils.PRIVATE_PRIVACY_VALUE),
      state: TaxonomyConceptMapper.mapToGeneratedConceptState(this.form.get(ConceptForm.Status).value.id),
      updatedAt: currentConcept.updatedAt,
      translations: this.store.selectSnapshot(TaxonomyConceptState.getConceptTranslations)?.availableConceptTranslations
    });
  }

  private checkIssues(error) {
    if (error?.message === ErrorsDao.OUTDATED_CONCEPT_ERROR) {
      this.openOutdatedConceptModal();
    } else {
      return throwError(error);
    }
  }

  public openOutdatedConceptModal() {
    const data: OutdatedConceptModalData = {
      id: null
    };

    const dialogRef = this.dialog.open(OutdatedConceptModalComponent, {
      data: data,
      ...OutdatedConceptModalComponent.DEFAULT_CONFIG
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(response => {
        if (response) {
          this.store.dispatch(new LoadConcept(this.concept.id));
        }
      });
  }

  public copyID() {
    const conceptId = this.form.get(ConceptForm.Id).value;
    navigator.clipboard.writeText(conceptId);

    this.notificationService.showSimpleAlert('The concept ID has been copied.', 'info');
  }

  public openTranslationLanguageModal() {
    this.selectSliderAdd = true;
    const translationLanguagesExcludingSelected = this.store
      .selectSnapshot(TaxonomyConceptState.translationLanguages)
      .filter(tl => !this.languageOptions.map(lo => lo.id).includes(tl.code) && tl.code !== this.DEFAULT_LANGUAGE);
    const data: TranslationLanguageModalData = {
      translationLanguages: translationLanguagesExcludingSelected
    };

    const dialogRef = this.dialog.open(TranslationLanguageModalComponent, {
      data: data,
      ...TranslationLanguageModalComponent.DEFAULT_CONFIG
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .pipe(
        switchMap(countryCode =>
          countryCode
            ? this.store.dispatch(new AddConceptTranslation({ selectedLanguage: countryCode }))
            : of(undefined)
        )
      )
      .subscribe(() => {
        const selectedTranslationLanguage = this.store.selectSnapshot(
          TaxonomyConceptState.getConceptTranslations
        )?.selectedTranslationLanguage;

        const countrySliderOption = {
          id: selectedTranslationLanguage.code,
          label: selectedTranslationLanguage.name,
          flagCode: selectedTranslationLanguage.country
        };
        this.form.get(ConceptForm.Slider).setValue(countrySliderOption);
        this.form.markAsDirty();

        this.selectSliderAdd = false;
      });
  }
}
